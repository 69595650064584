import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Reactmarkdown from "react-markdown";
import { SilentAny } from "../utils/types";
import Layout from "../components/Layout";
import Spacer from "../components/Spacer";
import { ArticleTitleImageStyled } from "../components/ArticleTitleImageStyled";
import { SEO } from "../components/SEO";
import { PageOuter } from "../components/PageOuter";
import styled from "styled-components";
import { AuthorProfile } from "../components/AuthorProfile";
import moment from "moment";
import { MarkDownResetStyles } from "../components/MarkdownResetStyles";
import { BlogPostPreviousAndNextButtons } from "../components/PreviousAndNextButtons";
import { ArrowLink } from "../components/ArrowLink";

const BlogPost = ({ pageContext }: { pageContext: SilentAny }): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const { blogPost, previousBlogPost, nextBlogPost } = pageContext;
  const {
    title,
    title_image,
    body,
    read_time_in_minutes,
    author,
    published_at,
  } = blogPost.node;

  //   const seo = {
  //     metaTitle: title,
  //     metaDescription: description,
  //     shareImage: image,
  //     blogPost: true
  //   };
  return (
    <Layout showScrollProgressBar>
      <SEO
        title={title}
        // description={body} // this needs to be an excerpt
        description="Follow the link to read the full blog post"
        image={title_image.childImageSharp.fluid.src}
      />
      <PageOuter title={title}>
        <MarkDownResetStyles>
          <h2 data-aos="fade" tabIndex={0}>
            {title}
          </h2>
          <Spacer />
          <PostDetailsRow data-aos="fade" data-aos-delay="250">
            <AuthorProfile
              author={author}
              additionalFootnoteText={`· ${read_time_in_minutes} min read · ${moment(
                published_at
              ).format("LL")}`}
              textTabIndex={0}
            />
          </PostDetailsRow>
          <Spacer height={4} />
          <div data-aos="fade" data-aos-delay="250">
            {title_image && (
              <>
                <ArticleTitleImageStyled
                  alt=""
                  fluid={title_image.childImageSharp.fluid}
                />
                <Spacer height={4} />
              </>
            )}
            <div tabIndex={0}>
              <Reactmarkdown>{body}</Reactmarkdown>
            </div>
          </div>
        </MarkDownResetStyles>
        <Spacer height={2} />

        <BlogPostPreviousAndNextButtons
          previousBlogPost={previousBlogPost}
          nextBlogPost={nextBlogPost}
        />
        <Spacer height={2} />
        <div data-aos="fade">
          <ArrowLink
            arrowLeft
            to="/blog"
            ariaLabel="Clickable link to the blog page"
          >
            Back to all blog posts
          </ArrowLink>
        </div>
      </PageOuter>
    </Layout>
  );
};
const PostDetailsRow = styled.div`
  display: flex;
  align-items: center;
`;

export default BlogPost;
